import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'

import '../src/css/style.css';
import "./vee-validate";

Vue.config.productionTip = false

/* Font Awesome setting */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarCheck, faTicketSimple, faPuzzlePiece, faBox, faUser, faPersonRunning, faHome, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faCalendarCheck, faTicketSimple, faPuzzlePiece, faBox, faUser, faPersonRunning, faHome, faTrash)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* datatable */
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';

Vue.use(VueCookies);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
