import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/Acceso',
    name: 'access',
    component: () => import('../views/AccessView.vue')
  },
  {
    path: '/Eventos',
    name: 'event',
    component: () => import('../views/EventView.vue')
  },
  {
    path: '/Cupones',
    name: 'coupon',
    component: () => import('../views/CouponView.vue')
  },
  {
    path: '/Categorias',
    name: 'category',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/SubCategorias',
    name: 'subCategory',
    component: () => import('../views/SubCategoryView.vue')
  },
  {
    path: '/Paquetes',
    name: 'package',
    component: () => import('../views/PackageView.vue')
  },
  {
    path: '/Anfitriones',
    name: 'host',
    component: () => import('../views/HostView.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
